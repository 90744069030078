// import { isEmpty } from "lodash";
import {
    mci_verification
} from "../../js/path";
import moment from "moment";

export default {
    data() {
        return {
            testing:true,
            other_institute_ref_no: "OTH45948759799436746",
            pageNo: null,
            mobile_number: '',
            verified: null,
            member_id: null,
            oldVerified: '',
            activeTab: 'processing-without',
            value: null,
            mci_state_selected: '',
            mciStates: [],
            qualification: '',
            message:'',
            messages: [],
            fields: [{
                    key: "member_id",
                    label: "Sr.no",
                    sortable: true
                },
                {
                    key: "date_of_joining",
                    label: "Date Of Joining",
                    sortable: true,
                    formatter: value => {
                        return moment(String(value)).format('Do MMMM YYYY')
                    }
                },

                {
                    key: "name",
                    label: "Name",
                    sortable: true,
                },
                {
                    key: "speciality",
                    label: "Speciality",
                    sortable: true
                },
                {
                    key: "is_prime",
                    label: "Member Type",
                    sortable: true
                },
                {
                    key: "mobile_number",
                    label: "Phone No",
                    sortable: true
                },
                {
                    key: "phone_no_verified",
                    label: "Phone No. Verified",
                    sortable: true
                },
                {
                    key: "email",
                    label: "Email ID",
                    sortable: true
                },
                {
                    key: "email_verify",
                    label: "Email ID Verified",
                    sortable: true
                },
                {
                    key: "email_verify",
                    label: "Email ID Verified",
                    sortable: true
                },
                {
                    key: "email_verify",
                    label: "Email ID Verified",
                    sortable: true
                },
                {
                    key: "is_lead_profile_approved",
                    label: "Approved Profile Lead Status"
                },
                {
                    key: "show_details",
                    label: "",
                    sortable: false,
                },
            ],
            verified_options: [{
                    text: "Select Type",
                    value: "0",
                },
                {
                    text: "Processing",
                    value: "3",
                },
                {
                    text: "Verified",
                    value: "1",
                },
                {
                    text: "Not Verified",
                    value: "2",
                },
                {
                    text: "Not Valid User",
                    value: "5",
                },
            ],
            color_options: [{
                    text: "All",
                    value: "all",
                },
                {
                    text: "Light Green",
                    value: "light_green",
                },
                {
                    text: "Light Yellow",
                    value: "light_yellow",
                },
                {
                    text: "Light Purple",
                    value: "light_purple",
                },
                {
                    text: "Light Red",
                    value: "light_red",
                },
            ],
            assign_options: [
                {
                    text: "Attend",
                    value: "false"
                },
                {
                    text: "Not-attend",
                    value: "true"
                }
            ],
            member_source_options: [
                {
                    text: "App",
                    value: "app"
                },
                {
                    text: "Website",
                    value: "website"
                }
            ],
            refferedby_options: [
                {
                    text: "Yes",
                    value: "true"
                },
                {
                    text: "No",
                    value: "false"
                }
            ],
            filter: null,
            color: null,
            assign: false,
            source: null,
            ref_filter: null,
            profile_listing: null,
            filterOn: [],
            sortBy: "",
            sortDesc: true,
            statusUpdate: "",
            currentPage: 1,
            tableData: [],
            key: 0,
            params: '&page=1',
            admin_id: this.admin_id,
            mod_val: '',
            institutes: [],
            course_list:[],
            qualification_data: {
                institute_name: "",
                qualification_detail_master_ref_no: "",
                start_date: null,
                end_date: null,
                currently_pursuing: 0,
                other_institute_name: "",
                other_course_name: "",
                other_institute_ref_no: "",
            },
            qualificationFields: [
                {
                    key: "index",
                    label: "SR NO"
                },
                {
                    key: "id",
                    label: "Member ID"
                },
                {
                    key: "institute_name",
                    label: "Institute Name",
                },
                {
                    key: "other_institute_name",
                    label: "Other Institute Name"
                },
                {
                    key: "course_name",
                    label: "Course Name",
                },
                {
                    key: "other_course_name",
                    label: "Other Course Name"
                },
                {
                    key: "start_date",
                    label: "Start Date",
                },
                {   
                    key: "end_date",
                    label: "End Date",
                },
                {
                    key: "currently_pursuing",
                    label: "Currently Pursuing"
                },
            ],
            qualificationData: [],
        };
    },
    methods: {
        async updateLeadStatus(id) {
            this.$store.commit("loader/updateStatus", true);
            try {
                let index = this.tableData.data.findIndex((e) => e.member_id === id);
                const url = mci_verification.updateLeadProfileStatus;
                const data = await this.postRequest(url, {
                    member_id: id,
                    status: (this.tableData.data[index].is_lead_profile_approved) ? 1:0 
                });
                if (data.status) {
                    this.$store.commit("toast/updateStatus", {
                        status: true,
                        icon: "success",
                        title: data.message,
                    });
                }
            } catch (err) {
                this.$store.commit("toast/updateStatus", {
                status: true,
                icon: "error",
                title: err.data ? err.data.message : "Please try again!",
                });
            }
            this.$store.commit("loader/updateStatus", false);
        },
        resetQualificationData(){
            var self = this;
            Object.keys(this.qualification_data).forEach(function(key) {
                self.qualification_data[key] = '';
              });
        },
        async submitQualificationData(id){
            this.$store.commit("loader/updateStatus", true);
            try {
                let url = mci_verification.updateQualification;
                let dataAppend = new FormData();
                dataAppend.append("institute_name", this.qualification_data.institute_name);
                dataAppend.append("qualification_detail_master_ref_no", this.qualification_data.qualification_detail_master_ref_no);
                dataAppend.append("qualification",this.qualification_data.qualification_detail_master_ref_no)
                dataAppend.append("start_date", this.qualification_data.start_date);
                dataAppend.append("end_date", this.qualification_data.end_date);
                dataAppend.append("member_id", id);
                dataAppend.append("other_institute_name", this.qualification_data.other_institute_name);
                dataAppend.append("other_course_name", this.qualification_data.other_course_name);
                if(this.qualification_data.institute_name == 'other'){
                    dataAppend.append("institute_master_ref_no",this.other_institute_ref_no)
                }
                if (this.qualification_data.currently_pursuing) {
                    dataAppend.append("currently_pursuing", 1);
                }
                else {
                    dataAppend.append("currently_pursuing", 0);
                }
                let data = await this.postRequest(url, dataAppend);
                if (data.status) {
                    this.$store.commit("toast/updateStatus", {
                        status: true,
                        icon: "success",
                        title: data.message,
                    });
                    this.resetQualificationData();
                }
            } catch(err) {
                this.$store.commit("toast/updateStatus", {
                    status: true,
                    icon: "error",
                    title: err.data ? err.data.message : "Please try again!"
                })
            }
            this.$store.commit("loader/updateStatus", false);
        },
        async fetchQualificationData(id){
            this.$store.commit("loader/updateStatus", true);
            try {
                let url = mci_verification.fetchQualification + id;
                let data = await this.getRequest(url);
                if (data.status) {  
                    this.qualificationData = data.response;
                }
            }
            catch(err) {
                this.$store.commit("toast/updateStatus", {
                    status: true,
                    icon: "error",
                    title: err.data ? err.data.message : "Please try again!",
                })
            }
            this.$store.commit("loader/updateStatus", false);
        },
        changePursue(){
            this.qualification_data.end_date = null;
        },
        getSessionStart : function (session_time) {
            // var date1 = new Date(session_time);
            let dateTime1 = moment(session_time).format('LLLL');

            return dateTime1;
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.verify_by_id == '38' || item.verify_by_id == '56') return 'table-success'
            else if (item.verify_by_id == '41' || item.verify_by_id == '55') return 'table-warning'
            else if (item.verify_by_id == '45' || item.verify_by_id == '57') return 'table-primary'
            else if (item.verify_by_id == '73') return 'table-danger'
            else var value = item.member_id % 4
             if(value == 0){
                return 'table-success'
             }
             if(value == 1){
                return 'table-warning'
             }
             if(value == 2){
                return 'table-primary'
             }
             if(value == 3){
                return 'table-danger'
             }
          },
        search(event) {
            if (this.filter.length > 1) {
                if (event.keyCode == 13) {
                    this.fetchData(this.activeTab);
                }
            } else if (this.filter.length == 0) this.fetchData(this.activeTab);
        },
        searchFor() {
            if (this.filter.length > 0) {
                this.fetchData(this.activeTab);
            } else {
                this.fetchData(this.activeTab);
            }
        },
        filterPage() {
            if (this.pageNo.length > 0) {
                this.params = `&page=${this.pageNo}`;
                this.fetchData(this.activeTab);
                this.currentPage = this.pageNo;
            }
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        filterDevice(val){
            console.log(val);
            if(val){
                this.fetchData(this.activeTab);
            }
        },
        filterReffer(val){
            console.log(val);
            if(val){
                this.fetchData(this.activeTab);
            }
        },
        onChangeProfileListing() {
            this.fetchData(this.activeTab);
        },
        filterAssign(val){
            console.log(val);
            if(val){
                this.fetchData(this.activeTab);
            }
        },
        filterColor(val){
            if(val){
                this.fetchData(this.activeTab);
            }
        },
        async fetchData(txt) {
            let url = null;
            if (txt == "all") {
                this.activeTab = txt;
                if (this.filter != null && this.filter != "") {
                    url = mci_verification.fetchMciVerification + "?filter=" + txt +  "&search=" + this.filter;
                }else if(this.color != null && this.color != ""){
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&color=" + this.color + "&unassigned=" + this.assign;
                }else if(this.assign != null && this.assign != ""){
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&unassigned=" + this.assign + "&color=" + this.color;
                }else if(this.source != null && this.source != ""){
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&source=" + this.source + "&ref_filter=" + this.ref_filter;
                }else if(this.ref_filter != null && this.ref_filter != ""){
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&ref_filter=" + this.ref_filter + "&source=" + this.source;
                } else {
                    url = mci_verification.fetchMciVerification + "?filter=" + txt;
                }
            } else if (txt == "processing") {
                this.activeTab = txt;
                if (this.filter != null && this.filter != "") {
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&color=" + this.color  + "&search=" + this.filter;
                } else {
                    url = mci_verification.fetchMciVerification + "?filter=" + txt;
                }
            } else if (txt == "processing-without") {
                this.activeTab = txt;
                if (this.filter != null && this.filter != "") {
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&search=" + this.filter;
                }else if(this.color != null && this.color != ""){
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&color=" + this.color + "&unassigned=" + this.assign;
                }else if(this.assign != null && this.assign != ""){
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&unassigned=" + this.assign + "&color=" + this.color;
                }else if(this.source != null && this.source != ""){
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&source=" + this.source + "&ref_filter=" + this.ref_filter;
                }else if(this.ref_filter != null && this.ref_filter != ""){
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&ref_filter=" + this.ref_filter + "&source=" + this.source;
                } else {
                    url = mci_verification.fetchMciVerification + "?filter=" + txt;
                }
            } else if (txt == "processing-with") {
                this.activeTab = txt;
                if (this.filter != null && this.filter != "") {
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&search=" + this.filter;
                }else if(this.color != null && this.color != ""){
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&color=" + this.color + "&unassigned=" + this.assign;
                }else if(this.assign != null && this.assign != ""){
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&unassigned=" + this.assign + "&color=" + this.color;
                }else if(this.source != null && this.source != ""){
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&source=" + this.source + "&ref_filter=" + this.ref_filter;
                }else if(this.ref_filter != null && this.ref_filter != ""){
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&ref_filter=" + this.ref_filter + "&source=" + this.source;
                } else {
                    url = mci_verification.fetchMciVerification + "?filter=" + txt;
                }
            } else if (txt == "without") {
                this.activeTab = txt;
                if (this.filter != null && this.filter != "") {
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&search=" + this.filter;
                }else if(this.color != null && this.color != ""){
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&color=" + this.color + "&unassigned=" + this.assign;
                }else if(this.assign != null && this.assign != ""){
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&unassigned=" + this.assign + "&color=" + this.color;
                }else if(this.source != null && this.source != ""){
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&source=" + this.source + "&ref_filter=" + this.ref_filter;
                }else if(this.ref_filter != null && this.ref_filter != ""){
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&ref_filter=" + this.ref_filter + "&source=" + this.source;
                }else if(this.profile_listing != null && this.profile_listing != ""){
                    url = mci_verification.fetchMciVerification + "?filter=" + txt;
                } else {
                    url = mci_verification.fetchMciVerification + "?filter=" + txt;
                }
            } else if (txt == "with") {
                this.activeTab = txt;
                if (this.filter != null && this.filter != "") {
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&search=" + this.filter;
                }else if(this.color != null && this.color != ""){
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&color=" + this.color + "&unassigned=" + this.assign;
                }else if(this.assign != null && this.assign != ""){
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&unassigned=" + this.assign + "&color=" + this.color;
                }else if(this.source != null && this.source != ""){
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&source=" + this.source + "&ref_filter=" + this.ref_filter;
                }else if(this.ref_filter != null && this.ref_filter != ""){
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&ref_filter=" + this.ref_filter + "&source=" + this.source;
                } else {
                    url = mci_verification.fetchMciVerification + "?filter=" + txt;
                }
            } else if (txt == "pending") {
                this.activeTab = txt;
                if (this.filter != null && this.filter != "") {
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&search=" + this.filter;
                } else {
                    url = mci_verification.fetchMciVerification + "?filter=" + txt;
                }
            } else if (txt == "not-verified") {
                this.activeTab = txt;
                if (this.filter != null && this.filter != "") {
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&color=" + this.color  + "&search=" + this.filter;
                }else if(this.color != null && this.color != ""){
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&color=" + this.color + "&unassigned=" + this.assign;
                }else if(this.assign != null && this.assign != ""){
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&unassigned=" + this.assign + "&color=" + this.color;
                }else if(this.source != null && this.source != ""){
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&source=" + this.source + "&ref_filter=" + this.ref_filter;
                }else if(this.ref_filter != null && this.ref_filter != ""){
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&ref_filter=" + this.ref_filter + "&source=" + this.source;
                } else {
                    url = mci_verification.fetchMciVerification + "?filter=" + txt;
                }
            } else if (txt == "not-verified-with-document") {
                this.activeTab = txt;
                if (this.filter != null && this.filter != "") {
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&color=" + this.color  + "&search=" + this.filter;
                }else if(this.color != null && this.color != ""){
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&color=" + this.color + "&unassigned=" + this.assign;
                }else if(this.assign != null && this.assign != ""){
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&unassigned=" + this.assign + "&color=" + this.color;
                }else if(this.source != null && this.source != ""){
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&source=" + this.source + "&ref_filter=" + this.ref_filter;
                }else if(this.ref_filter != null && this.ref_filter != ""){
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&ref_filter=" + this.ref_filter + "&source=" + this.source;
                } else {
                    url = mci_verification.fetchMciVerification + "?filter=" + txt;
                }
            } else if (txt == "call") {
                this.activeTab = txt;
                if (this.filter != null && this.filter != "") {
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&search=" + this.filter;
                }else if(this.color != null && this.color != ""){
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&color=" + this.color + "&unassigned=" + this.assign;
                }else if(this.assign != null && this.assign != ""){
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&unassigned=" + this.assign + "&color=" + this.color;
                }else if(this.source != null && this.source != ""){
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&source=" + this.source + "&ref_filter=" + this.ref_filter;
                }else if(this.ref_filter != null && this.ref_filter != ""){
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&ref_filter=" + this.ref_filter + "&source=" + this.source;
                } else {
                    url = mci_verification.fetchMciVerification + "?filter=" + txt;
                }
            } else if (txt == "not-valid") {
                this.activeTab = txt;
                if (this.filter != null && this.filter != "") {
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&search=" + this.filter;
                }else if(this.color != null && this.color != ""){
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&color=" + this.color + "&unassigned=" + this.assign;
                }else if(this.assign != null && this.assign != ""){
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&unassigned=" + this.assign + "&color=" + this.color;
                }else if(this.source != null && this.source != ""){
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&source=" + this.source + "&ref_filter=" + this.ref_filter;
                }else if(this.ref_filter != null && this.ref_filter != ""){
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&ref_filter=" + this.ref_filter + "&source=" + this.source;
                } else {
                    url = mci_verification.fetchMciVerification + "?filter=" + txt;
                }
            } else {
                url += '?'
            }
            this.getUrl();
            url += this.params;
            if(this.profile_listing)
            {
                url += "&show_lead_profile_members=1";
            }            
            this.$store.commit("loader/updateStatus", true);
            try {
                const data = await this.getRequest(url);
                if (data.status) {
                    const responseData = data.response;
                    this.tableData = responseData;
                }
            } catch (err) {
                this.$store.commit("toast/updateStatus", {
                    status: true,
                    icon: "error",
                    title: err.data ? err.data.message : "Please try again!",
                });
            }
            this.$store.commit("loader/updateStatus", false);
            this.key += 1;
        },
        mciDetails(val) {
            let index = this.tableData.data.findIndex(f => f.member_id == val.item.member_id);
            this.oldVerified = this.tableData.data[index].verified;
            // console.log(this.tableData.data[index].qualifications_by_speciality);
            // this.tableData.data[index].qualifications_by_speciality.push({
            //     "course_name": "MBBS",
            //     "id": '01',
            //     "qualification_detail_master_ref_no": "MBBS",
            //     "qualification_master_ref_no": "KAP109925973032190",
            //     "speciality_id": '01',
            // });
            this.tableData.data[index].qualifications_by_speciality.push({
                "course_name": "Other",
                "id": '02',
                "qualification_detail_master_ref_no": "Other",
                "qualification_master_ref_no": "Other",
                "speciality_id": '02',
            });
        },
        async changeStatus(val) {
            this.isClickedMciState = false;
            try {
                if (val.item.verified == 1) {
                    if (val.item.mci_document == "" || val.item.mci_document == null || val.item.mci_document == 'null') {
                        let res = await this.$bvModal.msgBoxOk('Please Add First Document', {
                            title: 'Confirmation',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'success',
                            headerClass: 'p-2 border-bottom-0',
                            footerClass: 'p-2 border-top-0',
                            centered: true,
                        })
                        if (res == true) {
                            let index = this.tableData.data.findIndex(f => f.member_id == val.item.member_id);
                            if (index != -1) {
                                this.tableData.data[index].verified = this.oldVerified;
                            }
                        }
                    } else {
                        let res = await this.$bvModal.msgBoxConfirm('Are you sure you want to Proceed?', {
                            title: 'Confirmation',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'success',
                            headerClass: 'p-2 border-bottom-0',
                            footerClass: 'p-2 border-top-0',
                            centered: true,
                        });
                        if (res == false) {
                            let index = this.tableData.data.findIndex(f => f.member_id == val.item.member_id);
                            if (index != -1) {
                                this.tableData.data[index].verified = this.oldVerified;
                            }
                        } else {
                            const url = mci_verification.mciUpdate;
                            let dataAppend = new FormData();
                            dataAppend.append("is_verified_with_mci", val.item.verified);
                            dataAppend.append("id", val.item.member_id);
                            const data = await this.postRequest(url, dataAppend);
                            if (data.status) {
                                this.$store.commit("toast/updateStatus", {
                                    status: true,
                                    icon: "success",
                                    title: data.message,
                                });
                            }
                        }
                    }
                } else {
                    const url = mci_verification.mciUpdate;
                    let dataAppend = new FormData();
                    dataAppend.append("is_verified_with_mci", val.item.verified);
                    dataAppend.append("id", val.item.member_id);
                    const data = await this.postRequest(url, dataAppend);
                    if (data.status) {
                        this.$store.commit("toast/updateStatus", {
                            status: true,
                            icon: "success",
                            title: data.message,
                        });
                    }
                }
            } catch (err) {
                console.log(err);
            }
        },
        async uploadMciFile(e, val) {
            this.mci_file = e.target.files[0];
            try {
                const url = mci_verification.mciUpload;
                let dataAppend = new FormData();
                dataAppend.append("mci_reg", this.mci_file);
                dataAppend.append("member_id", val.item.member_id);
                const data = await this.postRequest(url, dataAppend);
                if (data.status) {
                    let index = this.tableData.data.findIndex(f => f.member_id == val.item.member_id);
                    if (index != -1) {
                        this.image = this.tableData.data[index].mci_document;
                        this.tableData.data[index].mci_document = data.response.mci_document;
                    }
                    this.$store.commit("toast/updateStatus", {
                        status: true,
                        icon: "success",
                        title: data.message,
                    });
                    this.$refs.mci_file.reset();
                }
            } catch (err) {
                console.log(err);
            }
        },
        async submitEmail(val) {
            try {
                const url = mci_verification.mciEmailSubmit;
                let dataAppend = new FormData();
                dataAppend.append("email", val.item.email);
                dataAppend.append("id", val.item.member_id);
                const data = await this.postRequest(url, dataAppend);
                if (data.status) {
                    this.$store.commit("toast/updateStatus", {
                        status: true,
                        icon: "success",
                        title: data.message,
                    });
                }
                this.isClickedEmail = false;
            } catch (err) {
                console.log(err);
            }
        },
        async submitMobile(val) {
            try {
                const url = mci_verification.mciMobileSubmit;
                let dataAppend = new FormData();
                dataAppend.append("mobile_number", val.item.mobile_number);
                dataAppend.append("id", val.item.member_id);
                const data = await this.postRequest(url, dataAppend);
                if (data.status) {
                    this.$store.commit("toast/updateStatus", {
                        status: true,
                        icon: "success",
                        title: data.message,
                    });
                }
                this.isClickedPhone = false;
            } catch (err) {
                console.log(err);
            }
        },
        async submitMciNo(val) {
            try {
                const url = mci_verification.mciNoSubmit;
                let dataAppend = new FormData();
                dataAppend.append("mci_no", val.item.mci_no);
                dataAppend.append("id", val.item.member_id);
                const data = await this.postRequest(url, dataAppend);
                if (data.status) {
                    this.$store.commit("toast/updateStatus", {
                        status: true,
                        icon: "success",
                        title: data.message,
                    });
                }
                this.isClickedMciNo = false;
            } catch (err) {
                console.log(err);
            }
        },
        async submitMciYear(val) {
            try {
                const url = mci_verification.mciYearSubmit;
                let dataAppend = new FormData();
                dataAppend.append("mci_year", val.item.mci_year);
                dataAppend.append("id", val.item.member_id);
                const data = await this.postRequest(url, dataAppend);
                if (data.status) {
                    this.$store.commit("toast/updateStatus", {
                        status: true,
                        icon: "success",
                        title: data.message,
                    });
                }
                this.isClickedMciYear = false;
            } catch (err) {
                console.log(err);
            }
        },
        async submitName(val) {
            try {
                const url = mci_verification.mciNameSubmit;
                let dataAppend = new FormData();
                dataAppend.append("first_name", val.item.first_name);
                dataAppend.append("last_name", val.item.last_name);
                dataAppend.append("id", val.item.member_id);
                const data = await this.postRequest(url, dataAppend);
                if (data.status) {
                    this.$store.commit("toast/updateStatus", {
                        status: true,
                        icon: "success",
                        title: data.message,
                    });
                }
                this.isClickedName = false;
                // location.reload();
            } catch (err) {
                console.log(err);
            }
        },
        async submitMciReferrel(val) {
            try {
                const url = mci_verification.referredBySubmit;
                let dataAppend = new FormData();
                dataAppend.append("referred_by", val.item.referred_by);
                dataAppend.append("id", val.item.member_id);
                const data = await this.postRequest(url, dataAppend);
                if (data.status) {
                    this.$store.commit("toast/updateStatus", {
                        status: true,
                        icon: "success",
                        title: data.message,
                    });
                }
                this.isClickedMciReferrel = false;
            } catch (err) {
                console.log(err);
            }
        },
        async storeNotes(val,message) {
            try {
                const url = mci_verification.storeNotes;
                let dataAppend = new FormData();
                dataAppend.append("member_id", val);
                dataAppend.append("mci_note", message);
                const data = await this.postRequest(url, dataAppend);
                if (data.status) {
                    this.$store.commit("toast/updateStatus", {
                        status: true,
                        icon: "success",
                        title: data.message,
                    });
                }
                this.isClickedMciReferrel = false;
            } catch (err) {
                console.log(err);
            }
        },
        async changeMciState(val) {
            try {
                const url = mci_verification.fetchUpdateMciState;
                let index = this.tableData.data.findIndex(f => f.member_id == val.item.member_id);
                this.tableData.data[index].mci_state = val.item.mci_state;
                console.log(this.tableData.data[index].mci_state);
                let dataAppend = new FormData();
                dataAppend.append("mci_state", val.item.mci_state);
                dataAppend.append("id", val.item.member_id);
                const data = await this.postRequest(url, dataAppend);
                if (data.status) {
                    this.$store.commit("toast/updateStatus", {
                        status: true,
                        icon: "success",
                        title: data.message,
                    });
                }
                this.isClickedMciState = false;
            } catch (err) {
                console.log(err);
            }
        },
        async fetchUpdateMciState() {
            const url = mci_verification.fetchUpdateMciState;
            console.log(url);
            // let dataAppend = new FormData();
            // dataAppend.append("mci_no", val.item.mci_no);
            // dataAppend.append("id", val.item.member_id);
            // const data = await this.postRequest(url, dataAppend);
            // if (data.status) {
            //   this.state_selected = data.response.data;
            // }
        },
        async fetchState() {
            const url = mci_verification.fetchMciVerification;
            const data = await this.getRequest(url);
            if (data.status) {
                const responseData = data.response;
                if (responseData.data != '' && responseData.data != null) {
                    responseData.data.map((item) => {
                        this.mciStates = item.state_councils.map(val => {
                            return val.state;
                        });
                    });
                }
            }
        },
        async fetchNotes(val) {
            const url = mci_verification.fetchNotes + val;
            const data = await this.getRequest(url);
            this.messages = data.response;
        },
        async fetchInstitutes() {
            const url = mci_verification.fetchInstitutes;
            const data = await this.getRequest(url);
            // console.log(data.response);
            this.institutes = data.response;
        },
        async fetchCourses(id) {
            const url = mci_verification.fetchCourses + id;
            const data = await this.getRequest(url);
            console.log(data.response);
            this.course_list = data.response;
            this.course_list.push({
                "course_name": "MBBS",
                "id": '01',
                "qualification_detail_master_ref_no": "MBBS",
                "qualification_master_ref_no": "KAP109925973032190",
                "speciality_id": '01',
            });
            this.course_list.push({
                "course_name": "Other",
                "id": '02',
                "qualification_detail_master_ref_no": "Other",
                "qualification_master_ref_no": "Other",
                "speciality_id": '02',
            });
        },
        // async setQualification(val,event){
        //     try {
        //         const url = mci_verification.updateQualification;
        //         let index = this.tableData.data.findIndex(f => f.member_id == val.item.member_id);
        //         this.tableData.data[index].qualification = val.item.qualification;
        //         let dataAppend = new FormData();
        //         dataAppend.append("qualification", event);
        //         dataAppend.append("member_id", val.item.member_id);
        //         const data = await this.postRequest(url, dataAppend);
        //         if (data.status) {
        //             this.$store.commit("toast/updateStatus", {
        //                 status: true,
        //                 icon: "success",
        //                 title: data.message,
        //             });
        //         }
        //         // this.isClickedMciState = false;
        //     } catch (err) {
        //         console.log(err);
        //     }
        // },
        async submitCallToDoctor(val) {
            try {
                const url = mci_verification.doctorAssign;
                let dataAppend = new FormData();
                dataAppend.append("member_ref_no", val.item.member_ref_no);
                dataAppend.append("digi_mr_id", val.item.digiMR_id);
                const data = await this.postRequest(url, dataAppend);
                if (data.status) {
                    this.$store.commit("toast/updateStatus", {
                        status: true,
                        icon: "success",
                        title: data.message,
                    });
                } else {
                    this.$store.commit("toast/updateStatus", {
                        status: true,
                        icon: "error",
                        title: data.message,
                    });
                }
                this.isClickedCallDoctor = false;
            } catch (err) {
                console.log();
            }
        },
        getUrl() {
            var url = new URL(window.location);
            if (this.activeTab == null || this.activeTab == "") {
                this.activeTab = "processing";
            }
            (url.searchParams.has('filter') ? url.searchParams.set('filter', this.activeTab) : url.searchParams.append('filter', this.activeTab));
            if (this.filter != null && this.filter != "") {
                (url.searchParams.has('search') ? url.searchParams.set('search', this.filter) : url.searchParams.append('search', this.filter));
            } else {
                url.searchParams.delete('search');
            }
            if (this.color != null && this.color != "") {
                (url.searchParams.has('color') ? url.searchParams.set('color', this.color) : url.searchParams.append('color', this.color));
            } else {
                url.searchParams.delete('color');
            }
            if (this.assign != null && this.assign != "") {
                (url.searchParams.has('unassigned') ? url.searchParams.set('unassigned', this.assign) : url.searchParams.append('unassigned', this.assign));
            } else {
                url.searchParams.delete('unassigned');
            }
            if (this.source != null && this.source != "") {
                (url.searchParams.has('source') ? url.searchParams.set('source', this.source) : url.searchParams.append('source', this.source));
            } else {
                url.searchParams.delete('source');
            }
            if (this.ref_filter != null && this.ref_filter != "") {
                (url.searchParams.has('ref_filter') ? url.searchParams.set('ref_filter', this.ref_filter) : url.searchParams.append('ref_filter', this.ref_filter));
            } else {
                url.searchParams.delete('ref_filter');
            }
            if (this.currentPage != null && this.currentPage != "") {
                (url.searchParams.has('page') ? url.searchParams.set('page', this.currentPage) : url.searchParams.append('page', this.currentPage));
            } else {
                url.searchParams.set('page', 1);
            }
            url.search = url.searchParams;
            url = url.toString();
            history.pushState({}, null, url);
        },
    },
    watch: {
        tableData(v) {
            if (v) {
                return (this.tableData = v);
            }
        },
        currentPage: {
            handler: function (value) {
                if (typeof value != undefined) {
                    this.params = `&page=${value}`;
                    this.fetchData(this.activeTab);
                    this.pageNo = this.currentPage;
                }
            },
        },
        "currently_pursuing"(v) {
            if(v == 1) {    
                this.qualification_data.currently_pursuing = true;
            }
            else {
                this.qualification_data.currently_pursuing = false;
            }
        }
    },
    mounted() {
        this.fetchState();
        this.rowClass();
        this.fetchInstitutes();
        this.admin_id = localStorage.getItem('admin_id');
        // console.log(admin_id);
        if(this.admin_id == '73'){
            this.mod_val = '3';
        }
        if(this.admin_id == '57'){
            this.mod_val = '2';
        }
        if(this.admin_id == '55'){
            this.mod_val = '1';
        }
        if(this.admin_id == '56'){
            this.mod_val = '0';
        }
        this.activeTab = "processing-without";
        if (this.$route.query.filter) {
            this.activeTab = this.$route.query.filter;
        }
        if (this.$route.query.search) {
            this.filter = this.$route.query.search;
        }
        if (this.$route.query.color) {
            this.color = this.$route.query.color;
        }
        if (this.$route.query.assign) {
            this.assign = this.$route.query.assign;
        }
        if (this.$route.query.page && this.$route.query.page != 1) {
            this.currentPage = parseInt(this.$route.query.page);
        } else {
            this.fetchData(this.activeTab);
        }
    },
};